import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { MapPin, ChevronRight, ChevronLeft } from 'react-feather';
import useWindowSize from 'utils/useWindowSize';
import { useTheme, Typography, Box } from '@mui/material';

const Slider = ({ linkData, interval = 3000 }) => {
    const { sizes } = useWindowSize();
    const theme = useTheme();
    const [currentIndex, setCurrentIndex] = useState(0);

    const CustomNextButton = ({ onClick }) => (
        <button
            onClick={onClick}
            className="flex justify-center items-center -mt-10 absolute bg-[#035dac] right-0 top-1/2 w-10 h-10 cursor-pointer transition-colors duration-200 hover:bg-primary-700"
        >
            <ChevronRight color="white" size={28} />
        </button>
    );

    const CustomPrevButton = ({ onClick }) => (
        <button
            onClick={onClick}
            className="flex justify-center items-center -mt-10 absolute bg-[#035dac] left-0 top-1/2 w-10 h-10 cursor-pointer transition-colors duration-200 hover:bg-primary-700 z-1"
        >
            <ChevronLeft color="white" size={28} />
        </button>
    );

    const CustomIndicators = ({ cIndex, total }) => {
        return (
            <div
                className="absolute bottom-6 left-1/2 flex flex-row"
                style={{ marginLeft: -1 * total * 14 }}
            >
                {Array.from({ length: total }).map((_, index) => (
                    <div
                        key={index}
                        className="h-2.5 bg-[#035dac] rounded-full mx-1"
                        style={{
                            backgroundColor:
                                cIndex === index ? theme.palette.secondary : '#035dac',
                            width: cIndex === index ? 39 : 10,
                        }}
                    ></div>
                ))}
            </div>
        );
    };

    return (
        <div className="relative">
            <Carousel
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                infiniteLoop
                autoPlay
                interval={interval}
                className="relative"
                onChange={(index) => setCurrentIndex(index)}
                renderArrowNext={(onClickHandler) => <CustomNextButton onClick={onClickHandler} />}
                renderArrowPrev={(onClickHandler) => <CustomPrevButton onClick={onClickHandler} />}
            >
                {linkData &&
                    linkData.length > 0 &&
                    linkData.map((item) => (
                        <div key={item.id}>
                            <div
                                style={{
                                    position: 'relative',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    height: sizes.sm ? 439 : 639,
                                    width: '100%',
                                    margin: '0 auto',
                                    // Render background image for image type only
                                    backgroundImage: item.image.endsWith('.mp4')
                                        ? 'none'
                                        : `url(${item.image})`,
                                }}
                            >
                                {/* Render video if the item is a video */}
                                {item.image.endsWith('.mp4') && (
                                    <video
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        // controls
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                        }}
                                    >
                                        <source src={item.image} type="video/mp4" />
                                    </video>
                                )}

                                <Box className="absolute bottom-0 left-0 max-w-[700px] text-left m-20 flex flex-col">
                                    <Typography variant="body16" className="text-white">
                                        {item.subTitle}
                                    </Typography>
                                    <Typography
                                        variant={sizes.sm ? 'h30' : 'h61'}
                                        className="text-white"
                                    >
                                        {item.title}
                                    </Typography>
                                </Box>
                            </div>
                        </div>
                    ))}
            </Carousel>

            {linkData && linkData.length > 0 && (
                <CustomIndicators cIndex={currentIndex} total={linkData.length} />
            )}
        </div>
    );
};

export default Slider;
