export const REFRESH_USER_COUNT = 'REFRESH_USER_COUNT';

import { get, put, post, destroy, upload } from 'utils/fetchRequest';

export const uploadImage = async (data) => {
    try {
        const result = await upload('files/cover', data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const getCoverList = async (searchText) => {
    try {
        const result = await get(`cover${searchText ? `?search=${searchText}` : ''}`);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const getCoverContent = async (newsId) => {
    try {
        const result = await get(`cover/${newsId}`);
        return result;
    } catch (err) {
        return { error: err };
    }
};

export const createCover = async (data) => {
    try {
        const result = await post('cover', data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const updateCover = async (id, data) => {
    try {
        const result = await put(`cover/${id}`, data);

        console.log(id)

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const deleteCover = async (id) => {
    try {
        const result = await destroy(`cover/${id}`);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const setSpecial = async (id, data) => {
    try {
        const result = await put(`cover/special/${id}`, data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

// export const subscribeUser = async (id) => {
//     try {
//         const result = await put(`payment/admin/${id}`);

//         return result;
//     } catch (err) {
//         return { error: err };
//     }
// };

// export const getUserCount = async () => {
//     try {
//         const result = await get(`users/count`);

//         return result;
//     } catch (err) {
//         return { error: err };
//     }
// };
