import React, { useEffect, useState } from "react";
import { Box, Typography, Container, Button } from "@mui/material";

import HomeLayout from "layouts/HomeLayout";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import useWindowSize from "utils/useWindowSize";
// actions
import { getCoverList } from "context/actions/cover";

// components
import Slider from "components/Slider";
import InfoBox from "components/pages/home/InfoBox";

const HomePage = ({}) => {
  const { t } = useTranslation();

  const { sizes } = useWindowSize();
  const [covers, setCovers] = useState([]);

  useEffect(() => {
    getInitData();
  }, []);
  const getInitData = async () => {
    const resCover = await getCoverList();
    if (resCover.status === "success") setCovers(resCover.data);
  };


  return (
    <Container maxWidth={false} disableGutters={true}>
      <Box>
        <Box>
          <Slider linkData={covers} />
          <InfoBox
            image={"/pages/home/ownerProfile.jpeg"}
            title={t("title.introduction")}
            paragraph={[t("paragraph.about_us"), t("paragraph.our_history_2")]}
          />
          <InfoBox
            isReverse={true}
            image={"/pages/home/active.jpeg"}
            title={t("title.future_view")}
            paragraph={[
              t("paragraph.future_view_1"),
              t("paragraph.future_view_2"),
              t("paragraph.future_view_3"),
              t("paragraph.future_view_4"),
            ]}
          />
          <InfoBox
            image={"/pages/home/contact.jpeg"}
            title={t("title.our_help")}
            paragraph={[
              t("paragraph.our_help_1"),
              t("paragraph.our_help_2"),
              t("paragraph.our_help_3"),
              t("paragraph.our_help_4"),
            ]}
          />
        </Box>
      </Box>
    </Container>
  );
};

HomePage.Layout = HomeLayout;

export default HomePage;
// #00afad
