import { Box, MenuItem, Typography, Select, Container, Grid2 as Grid } from '@mui/material';
import Image from 'next/image';

import useWindowSize from 'utils/useWindowSize';
const InfoBox = ({
    isReverse = false,
    image,
    title,
    paragraph = [],
    bottom = false,
    asset1,
    asset2,
    bigasset,
}) => {
    const { sizes } = useWindowSize();
    return (
        <>
            <Container maxWidth={false} disableGutters={true} className="flex flex-col relative">
                {/* <div className="w-[9px] h-[9px] rounded-full bg-primary absolute left-1/2 -ml-[4px] top-[44px] z-1"></div> */}
                <div className="w-full h-px absolute top-12"></div>
                {bigasset && bigasset}
                <Container
                    maxWidth={'lg'}
                    className="relative flex flex-row items-center justify-center  overflow-hidden"
                >
                    {asset1 && asset1}
                    {asset2 && asset2}
                    <Grid
                        container
                        className={`flex-1 flex ${isReverse ? '!flex-row-reverse' : 'flex-row'}  `}
                    >
                        <Grid
                            item="true"
                            size={{ lg: 6, md: 6, sm: 6, xs: 12 }}
                            className={`${sizes.xs ? 'p-4' : 'p-12'}  pt-24 ${sizes.xs ? 'pb-6' : ''} ${!(isReverse && !sizes.xs) ? '' : ' border-l border-l-[1px] border-[#e8e8e8]'}${bottom ? ' pb-24' : ''}`}
                        >
                            <Image
                                width={1}
                                height={1} // Specify the height here
                                src={image}
                                layout="responsive"
                                priority
                                // fill
                                // src={require('public/cover.png')}
                                alt=""
                                className="object-contain"
                            />
                        </Grid>
                        <Grid
                            item="true"
                            size={{ lg: 6, md: 6, sm: 6, xs: 12 }}
                            className={`flex-1 ${sizes.xs ? 'p-4' : 'p-12'} pt-24 ${sizes.xs ? '!pt-0' : ''} flex flex-col  ${!isReverse && !sizes.xs ? 'border-l border-l-[1px] border-[#e8e8e8]' : ''}${bottom ? ' pb-24' : ''}`}
                        >
                            <Typography
                                variant="h30"
                                className="font-normal"
                                style={{ fontFamily: 'Philosopher, sans-serif' }}
                            >
                                {title}
                            </Typography>
                            <Box className="flex flex-col">
                                {paragraph.map((item, ind) => {
                                    return (
                                        <Typography key={ind} variant="body16" className="mt-6">
                                            {item}
                                        </Typography>
                                    );
                                })}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <div
                    className={`w-full h-px bg-[#e8e8e8] absolute ${bottom ? 'bottom-12' : 'bottom-0'}`}
                ></div>
                {/* <div
                    className={`w-[9px] h-[9px] rounded-full bg-primary absolute left-1/2 -ml-[4px] ${bottom ? 'bottom-[44px]' : '-bottom-1'} z-1`}
                ></div> */}
            </Container>
        </>
    );
};

export default InfoBox;
